import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { useRootStore } from '~/stores/root'

import { providePushToCurrentUserFamily } from '~/api-core/Family/index'

export function useAddAccountToFamily() {
  const { $pinia } = useNuxtApp()
  const { coreFetch } = useProvideCoreFetch()
  const { ADD_NEW_ACCOUNT } = useRootStore($pinia)

  return async function (token: string) {
    const pushToCurrentUserFamily = providePushToCurrentUserFamily(coreFetch)
    const account = await pushToCurrentUserFamily(token)

    return ADD_NEW_ACCOUNT(account)
  }
}
