import { useAddAccountToFamily } from './useAddAccountToFamily'

import { useNotificationSocket } from '~/composables/useNotificationSocket'
import { useProvideAuthFetch } from '~/composables/useProvideAuthFetch'
import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { useLoginStore } from '~/stores/login'

import { backupAccountSecret } from '~/helpers/SwapAccount'

import { provideAuthApiLogin } from '~/api-auth/Login'
import {
  provideLoginSession,
  provideLogoutSession,
} from '~/api-core/Login/Session'

export function useLogin() {
  const { $pinia } = useNuxtApp()
  const { coreFetch } = useProvideCoreFetch()
  const { authFetch } = useProvideAuthFetch()
  const { SET_LOADING_STATUS: LOGIN_SET_LOADING_STATUS } = useLoginStore($pinia)
  const { reloadSocket } = useNotificationSocket()
  const addAccountToFamily = useAddAccountToFamily()

  return async function (
    {
      email,
      password,
    }: {
      email: string
      password: string
    },
    linkUsers = false,
  ) {
    LOGIN_SET_LOADING_STATUS(true)

    try {
      const loginToAuth = provideAuthApiLogin(authFetch)
      const loginToCore = provideLoginSession(coreFetch)
      const logoutFromCore = provideLogoutSession(coreFetch)
      const { access } = await loginToAuth({ email, password })
      if (linkUsers) await addAccountToFamily(access)

      await logoutFromCore()
      const user = await loginToCore(access)

      if (linkUsers) backupAccountSecret(user.id, access)

      reloadSocket()
      return user
    } finally {
      LOGIN_SET_LOADING_STATUS(true)
    }
  }
}
